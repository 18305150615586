import { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import UserContext, { User } from './contexts/User';

import {
  Container,
} from '@material-ui/core';

import theme from './ui/Theme';
import ErrorBoundary from './ErrorBoundary';
import Parts from './ui/Parts';
import Login from './ui/Login';

import 'firebase/firestore';
import {
  AuthCheck,
  useUser,
  useFirestore,
  useFirestoreDocDataOnce,
} from 'reactfire';

export default function App() {
  const authUser = useUser();
  const usersCollection = useFirestore().collection("users");
  const { data: userDoc } = useFirestoreDocDataOnce<User>(usersCollection.doc(authUser?.data?.uid));
  const [user, setUser] = useState({} as User);

  useEffect(() => {
    setUser({
      uid: authUser?.data?.uid,
      email: authUser?.data?.email,
      displayName: authUser?.data?.displayName,
      name: userDoc?.name,
    });
  }, [
    authUser?.data,
    userDoc,
  ]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <ThemeProvider theme={theme}>
        <br />
        <Container maxWidth="md">
          <ErrorBoundary fallback={authUser?.data?.uid}>
            <AuthCheck fallback={<></>}>
              <Parts />
            </AuthCheck>
          </ErrorBoundary>
        </Container>
        <Login />
      </ThemeProvider>
    </UserContext.Provider>
  );
}
