import firebase from 'firebase/app';

import {
    useAuth,
} from 'reactfire';

import {
    Button,
} from '@material-ui/core';

export default function GoogleLoginButton(props: {
    loginText?: string,
    loginStartIcon?: React.ReactNode,
    logoutText?: string,
    logoutStartIcon?: React.ReactNode,
    signInMethod?: "redirect" | "popup",
    refreshOnLogout?: boolean,
}) {
    const auth = useAuth();
    const signInMethod = props.signInMethod || "popup";

    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        if (signInMethod === "redirect") auth.signInWithRedirect(provider);
        if (signInMethod === "popup") auth.signInWithPopup(provider);
    };

    const signOut = () => {
        auth.signOut()
            .then(() => {
                if (props.refreshOnLogout) {
                    document.location.reload();
                }
            })
            .catch((e) => {
                console.error("SIGNOUT ERROR: %s", e);
                console.error(e);
            });
    };

    if (auth.currentUser !== null) return (<Button onClick={signOut} variant="contained" startIcon={props.logoutStartIcon}>{props.logoutText || "Logout"}</Button>);

    return (<Button onClick={signInWithGoogle} variant="contained" startIcon={props.loginStartIcon}>{props.loginText || "Login"}</Button>);
}