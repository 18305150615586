const googleCalendarEventColors = [
    "#a4bdfc", // 1
    "#7ae7bf", // 2
    "#dbadff", // 3
    "#ff887c", // 4
    "#fbd75b", // 5
    "#ffb878", // 6
    "#46d6db", // 7
    "#e1e1e1", // 8
    "#5484ed", // 9
    "#51b749", // 10
    "#dc2127", // 11
] as const;

const googleCalendarEventColorsIndex = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const;

export type GoogleCalendarEventColorValue = typeof googleCalendarEventColors[number];
export type GoogleCalendarEventColorIndex = typeof googleCalendarEventColorsIndex[number];

export function GoogleCalendarEventColorIndexFromValue(color: GoogleCalendarEventColorValue): GoogleCalendarEventColorIndex {
    return (googleCalendarEventColors.indexOf(color) + 1) as GoogleCalendarEventColorIndex;
}

export function GoogleCalendarEventColorValueFromIndex(index: GoogleCalendarEventColorIndex): GoogleCalendarEventColorValue {
    return googleCalendarEventColors[index - 1];
}