import React, { useState, FunctionComponent } from 'react';

type SetValue = (value: User) => void;

interface UserContextInterface {
  user: User,
  setUser: SetValue,
}

export const UserContext = React.createContext<UserContextInterface>({
  user: {},
  setUser: (value: User) => { console.log("USERCONTEXT NOT INITIALIZED") },
});

export interface User {
  uid?: string | null,
  name?: string | null,
  displayName?: string | null,
  email?: string | null,
}

export default UserContext;