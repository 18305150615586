import GoogleLoginButton from './GoogleAuth';

import {
    Box,
} from '@material-ui/core';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import 'firebase/firestore';
import {
    useUser,
} from 'reactfire';

export default function Login() {
    const user = useUser();

    return (
        <Box style={{ display: "flex", padding: "4vh" }}>
            <Box style={{ margin: "auto" }}>
                <GoogleLoginButton refreshOnLogout={true} loginText="Login with Google" loginStartIcon={<AccountCircleIcon />} logoutText={"Logout " + (user.data?.email || "")} logoutStartIcon={<CancelIcon />} />
            </Box>
        </Box>
    );
}