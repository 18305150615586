import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

import firebase from 'firebase/app';
import {
  FirebaseAppProvider,
  preloadFirestore,
  preloadFunctions,
  preloadAuth,
} from 'reactfire';

const isProduction = true;//process.env.NODE_ENV === "production" && window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1";
document.body.classList.add(isProduction ? 'appEnvProd' : 'appEnvDev');

fetch("/__/firebase/init.json")
  .then(async (response) => {
    const firebaseConfig = await response.json();
    const firebaseApp = firebase.initializeApp(firebaseConfig);

    await preloadFirestore({
      firebaseApp,
      setup: async (factory) => {
        const firestore = factory();
        if (!isProduction) firestore.useEmulator(window.location.hostname, 8080);
        return firestore;
      },
    });

    await preloadFunctions({
      firebaseApp,
      setup: async (factory) => {
        const functions = factory();
        if (!isProduction) functions.useEmulator(window.location.hostname, 5001);
        return functions;
      },
    });

    await preloadAuth({
      firebaseApp,
      setup: async (factory) => {
        const auth = factory();
        // if (!isProduction) auth.useEmulator(`http://${window.location.hostname}:9099`);
        return auth;
      },
    });

    ReactDOM.render(
      <React.StrictMode>
        <FirebaseAppProvider firebaseApp={firebaseApp}>
          <App />
        </FirebaseAppProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();