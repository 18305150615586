import { useContext } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import UserContext from '../contexts/User';

import {
  Grid,
} from '@material-ui/core';

import { Part } from '../../types/Part';
import PartCard from './PartCard';

import 'firebase/firestore';
import {
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';

function PartsInternal() {
  const { user } = useContext(UserContext);
  const partsCollection = useFirestore().collection("parts");
  const { error, status, data: parts } = useFirestoreCollectionData<Part>(partsCollection, { idField: "id" });

  interface FirebaseError {
    name: string,
    code: string,
  }

  const firebaseError = (error: Error) => (error as unknown as FirebaseError).code;

  return (
    <Grid container justify="center" spacing={4}>
      {status !== "success" && (
        <Grid item>
          {status}
        </Grid>
      )}
      {error && firebaseError(error) === "permission-denied" && (
        <Grid item>
          <p style={{ fontSize: "5vw" }}>Give this value to the TeaPod:</p>
          <p style={{ fontSize: "5vw", fontFamily: "monospace" }}>{user?.uid}</p>
        </Grid>
      )}
      {error && firebaseError(error) !== "permission-denied" && (
        <Grid item>
          {"🤷‍♀️ " + firebaseError(error)}
        </Grid>
      )}
      {status === "success" && !error && parts && Array.isArray(parts) && parts.sort((a: Part, b: Part) => {
        if ((a && a.order) && (!b || b.order === undefined)) return -1;
        if ((b && b.order) && (!a || a.order === undefined)) return 1;
        if ((!a || a.order === undefined) && (!b || b.order === undefined)) return 0;
        return (a && a.order !== undefined ? a.order : 0) - (b && b.order !== undefined ? b.order : 0);
      }).map((part: Part) => (
        <Grid item key={part.id}>
          <PartCard part={part} elevation={5} />
        </Grid>
      ))}
    </Grid>
  );
}

export const Parts = withErrorBoundary(PartsInternal, {
  FallbackComponent: function ({ error }) {
    return (
      <>
        <h1>There has been an error:</h1>
        <h4>{String(error)}</h4>
      </>
    );
  },
});

export default Parts;