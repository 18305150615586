import { createMuiTheme } from '@material-ui/core/styles';

// #624977 is SW 6832 Impulsive Purple

export default createMuiTheme({
    palette: {
        primary: {
            main: "#624977"
        }
    },
    typography: {
    }
});