import { useState, useContext } from 'react';
import UserContext from '../contexts/User';

import {
  Part,
} from '../../types/Part';

import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Divider,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';

import {
  useFunctions,
} from 'reactfire';

import { GoogleCalendarEventColorValueFromIndex } from '../../types/GoogleCalendarEventColors';

// TODO: inherit app styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 250,
    },
    content: {
      minHeight: 100,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export default function PartCard(props: {
  part: Part,
  elevation?: number,
}) {
  const part = props.part;
  const classes = useStyles();

  const { user } = useContext(UserContext);

  const eventColorIndex = part.googleCalendarEventColorIndex;
  const eventColor = eventColorIndex ? GoogleCalendarEventColorValueFromIndex(eventColorIndex) : "";

  const [open, setOpen] = useState(false);
  const [reporter, setReporter] = useState("");
  const [note, setNote] = useState("");
  const [switching, setSwitching] = useState(false);
  const [errorText, setErrorText] = useState("");

  const setFront = useFunctions().httpsCallable("setFront");

  function switchInPart(
    part: Part,
    reporter: string,
    note?: string,
  ) {
    setSwitching(true);
    setFront({
      id: part.id,
      reporter: reporter,
      datetime: new Date().toJSON(),
      note: note,
    })
      .then((value) => {
        setSwitching(false);
        setErrorText("");
      })
      .catch((e) => {
        setSwitching(false);
        setErrorText(String(e));
      });
  }

  function openDialog(state: boolean) {
    setReporter(user.uid === "NPxAJHrYz9WxTpLS9ye6btOaIoI2" ? part.name : user.name || "");
    setNote("");
    setOpen(state);
  }

  return (
    <Card className={classes.root} elevation={props.elevation}>
      <CardContent className={classes.content}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography component="p" variant="h4">{part.name}</Typography>
          </Grid>
          <Grid item>
            <Typography component="p" variant="h5">{typeof part.age === "number" ? part.age : "❔"}</Typography>
          </Grid>
        </Grid>
        <Typography component="p">{part.pronouns || "unknown pronouns"}</Typography>
      </CardContent>
      <Divider variant="fullWidth" />
      <CardActions style={{ backgroundColor: eventColor }}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Button size="small" variant="contained" color="primary" onClick={() => openDialog(true)} disabled={part.fronting && part.frontingButtonAppearFalse !== true} startIcon={<TransferWithinAStationIcon />}>Switch</Button>
          </Grid>
          <Grid item>
            <IconButton size="small" color="primary" aria-label={"edit " + part.name} disabled={part.readonly}><EditIcon /></IconButton>
          </Grid>
        </Grid>
      </CardActions>
      <Dialog open={open} onClose={() => openDialog(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Switch to {part.name}</DialogTitle>
        <DialogContent>
          <TextField autoFocus={reporter === ""} fullWidth margin="dense" id="reporter" label="Reporter" type="text" autoComplete="given-name" value={reporter} onChange={(e) => setReporter(e.target.value)} disabled={user.uid !== null && user.uid !== "NPxAJHrYz9WxTpLS9ye6btOaIoI2"} />
          <TextField autoFocus={reporter !== ""} fullWidth margin="dense" id="note" label="Note" type="text" autoComplete="off" value={note} onChange={(e) => setNote(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button disabled={reporter === ""} size="small" variant="contained" color="primary" onClick={() => {
            switchInPart(part, reporter, note);
            openDialog(false);
          }} startIcon={<TransferWithinAStationIcon />}>Switch</Button>
          <Button size="small" variant="outlined" color="secondary" onClick={() => {
            openDialog(false);
          }} startIcon={<CancelIcon />}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={errorText !== ""} onClose={() => setErrorText("")} aria-labelledby="form-errorDialog-title">
        <DialogTitle id="form-errorDialog-title">Error Switching to {part.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" color="secondary" onClick={() => { setErrorText("") }}>Close</Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={switching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Card>
  );
}