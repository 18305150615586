import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children: ReactNode,
    fallback: string,
}

interface State {
    error: string,
}

export default class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        error: ""
    };

    public static getDerivedStateFromError(error: Error): State {
        return {
            error: error.message,
        };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("OH GOSH DANG...", error, errorInfo);
    }

    public render() {
        if (this.state.error) {
            return (
                <>
                    <h1>There has been an error:</h1>
                    <h4>{this.state.error}</h4>
                    <hr />
                    <p style={{ fontSize: "5vw" }}>Give this value to the TeaPod:</p>
                    <p style={{ fontSize: "5vw", fontFamily: "monospace" }}>{this.props.fallback}</p>
                </>
            );
        } else {
            return this.props.children;
        }
    }
}